import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Summary from 'content/blog/en/vercel-like-paas-summary';
export const _frontmatter = {
  "title": "A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part II",
  "subtitle": "Gitlab pipeline and CI/CD configuration",
  "cover": "k8s+gitlab.webp",
  "description": "This second part explains how to configure a GitLab pipeline and CI/CD variables.\n",
  "meta": {
    "title": "A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part II",
    "description": "How to configure Gitlab pipeline to automate deployments with Kubernetes"
  },
  "tags": ["Kubernetes", "GitOps", "DevOps", "Automation"],
  "publishedAt": "2022-02-24T10:00:00",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This article is the `}<strong parentName="p">{`second part`}</strong>{` of the `}<em parentName="p">{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps`}</em>{` series.`}</p>
    <Summary mdxType="Summary" />
    <hr></hr>
    <p><em parentName="p">{`After installing a k0s Kubernetes cluster in `}<a parentName="em" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part1"
        }}>{`part I`}</a>{`,
I'm now going to set up repositories and CI/CD configuration. Then, I'll set up
a pipeline to build and deploy applications to Kubernetes. This is what it looks like
when completed:`}</em></p>
    <img alt="gitlab pipeline interface" caption="A Vercel-like pipeline done with GitLab" frame="box" src="gitlab-k0s-pipeline.webp" width={80} />
    <p><em parentName="p">{`I'm using GitLab here mostly because I've been using it for 10 years and it's
the platform I know the most. In my opinion, it has always been the best platform
of its market. However, the competition has closed the gap in the last years
and picking one platform instead of another is mostly about preferences or partnership.`}</em></p>
    <p><em parentName="p">{`I've already set up the same workflow with Azure DevOps in the past so I'm confident
it could be set up with many other CI/CD platforms. I'm not using GitLab's Kubernetes
integration features here.`}</em></p>
    <p><em parentName="p">{`In the future, I might use `}<a parentName="em" {...{
          "href": "https://dagger.io/"
        }}>{`Dagger`}</a>{` to implement
cross-platform CI workflow.`}</em></p>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#0"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#1"
        }}>{`Grant GitLab CI/CD access to the Kubernetes cluster`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#2"
        }}>{`Grant Kubernetes access to the image registry`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#3"
        }}>{`Add pipeline configuration file and stage declaration`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#4"
        }}>{`The `}<em parentName="a">{`package`}</em>{` stage configuration`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#5"
        }}>{`The `}<em parentName="a">{`deploy`}</em>{` stage configuration`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#6"
        }}>{`The `}<em parentName="a">{`promote`}</em>{` stage configuration`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#7"
        }}>{`The `}<em parentName="a">{`delete`}</em>{` stage configuration`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#8"
        }}>{`Next step`}</a></li>
    </ol>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@0"
      }}>{`Introduction`}</a></h2>
    <p>{`For the purpose of this experiment, I've created `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs"
      }}>{`Node.js`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/php"
      }}>{`PHP`}</a>{`, `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/python"
      }}>{`Python`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/ruby"
      }}>{`Ruby`}</a>{` web applications. These are the applications I'll deploy
to the Kubernetes cluster. They all have the same two endpoints:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`/version`}</inlineCode>{` returns the commit short hash of the built code, such as `}<em parentName="p">{`7c77eb36`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`/`}</inlineCode>{` returns a random name such as `}<em parentName="p">{`distracted_clarke`}</em></p>
      </li>
    </ul>
    <p>{`To generate names, I've copied the `}<a parentName="p" {...{
        "href": "https://github.com/docker/docker-ce/blob/9cb779b328f0aef9439a1238b8b5a147e7fdff12/components/engine/pkg/namesgenerator/names-generator.go"
      }}>{`code`}</a>{`
from Docker-CE that generate Docker container names and translated it to the appropriate language.`}</p>
    <p>{`I also added runtime information in a HTTP header:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ curl -i https://nodejs.k0s.gaudi.sh | grep env
env: node-16.13.2
`}</code></pre>
    <p>{`For the simplicity of the experiment, I've kept these apps very basic. One might
think a higher complexity in the code could impact the reliability of the setup.
I'm confident it does not.`}</p>
    <p>{`I've been running much more complex `}<a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`Next.js`}</a>{` and `}<a parentName="p" {...{
        "href": "https://nestjs.com/"
      }}>{`NextJS`}</a>{`
apps, some with an in-memory MongoDB servers, on AKS using a similar setup. For
about two years now, it has been reliable as long as allocated hardware resources
follows the cluster workload.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@1"
      }}>{`1. Grant GitLab CI/CD access to the Kubernetes cluster`}</a></h2>
    <p><em parentName="p">{`The first thing I want to do is allow GitLab to query the Kubernetes cluster from
pipeline jobs. To do so, I need to provide GitLab with the cluster's API URL,
and a token to perform requests as an authorized user. I'll store these
values in `}<a parentName="em" {...{
          "href": "https://docs.gitlab.com/ee/ci/variables"
        }}>{`GitLab's CI/CD variables`}</a>{`.`}</em></p>
    <p>{`I've added every application repository to the `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples"
      }}>{`k0s-examples`}</a>{`
group so that they can share the same inherited group CI/CD variables. Then,
I'll only have to update the group variables when I recreate a fresh Kubernetes cluster.`}</p>
    <p>{`Variables must be added in the `}<em parentName="p">{`Settings > CI/CD > Variables`}</em>{` section of the group.`}</p>
    <p>{`Once values are added and revealed, the section looks like this:`}</p>
    <img alt="gitlab ci/cd variables" caption="GitLab's CI/CD variables interface" frame="box" src="gitlab-variables.webp" width={80} />
    <p>{`These variables will be used to configure `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{` at the `}<a parentName="p" {...{
        "href": "#5"
      }}><em parentName="a">{`deploy`}</em></a>{` stage.`}</p>
    <h3><a parentName="h3" {...{
        "href": "@1-1"
      }}>{`The `}<inlineCode parentName="a">{`KUBE_URL`}</inlineCode>{` variable`}</a></h3>
    <p>{`The cluster API URL can be retrieved from the k0s server terminal I've opened
in `}<a parentName="p" {...{
        "href": "/en/blog/build-paas-kubernetes-gitops-part1"
      }}>{`part`}{` `}{`I`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ sudo k0s kubeconfig admin \\
 | sed 's/'$(ip r | grep default | awk '{ print $9}')'/'$(curl -s ifconfig.me)'/g' \\
 | grep 'server: https' \\
 | awk '{ print $2 }'
WARN[2022-02-07 22:51:36] no config file given, using defaults
https://3.89.90.202:6443
`}</code></pre>
    <h3><a parentName="h3" {...{
        "href": "@1-2"
      }}>{`The `}<inlineCode parentName="a">{`KUBE_TOKEN`}</inlineCode>{` variable`}</a></h3>
    <p>{`I create an admin user for GitLab from the k0s server and copy paste its authentication
token from the terminal to GitLab:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# first create gitlab user

$ sudo k0s kubectl create serviceaccount gitlab -n kube-system
$ sudo k0s kubectl create clusterrolebinding gitlab \\
  --clusterrole=cluster-admin \\
  --serviceaccount=kube-system:gitlab

# then print the token value

$ sudo k0s kubectl get secrets \\
  -n kube-system \\
  -o jsonpath="{.items[?(@.metadata.annotations['kubernetes\\.io/service-account\\.name']=='gitlab')].data.token}" \\
  | base64 --decode

eyJhbGciOiJSUzI1NiIsImtpZCI6IkpNa2lUUTVLTVJiVExDcUY5Y0JEaDY2MEZNT3NEM1Z...
`}</code></pre>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@2"
      }}>{`2. Grant Kubernetes access to the image registry`}</a></h2>
    <p><em parentName="p">{`Once GitLab can query the cluster, I have to allow GitLab to push images into
a registry and to allow Kubernetes to pull those images.`}</em></p>
    <p>{`GitLab automatically provides an image registry for each repository, called `}<em parentName="p">{`Container Registry`}</em>{`,
where I'll push Docker images built at the `}<a parentName="p" {...{
        "href": "#3-1"
      }}><em parentName="a">{`package`}</em></a>{` stage of the GitLab pipeline
and then pull them from the Kubernetes cluster at the `}<a parentName="p" {...{
        "href": "#3-2"
      }}><em parentName="a">{`deploy`}</em></a>{` stage.`}</p>
    <p>{`From `}<em parentName="p">{`Settings > Repository > Deploy token`}</em>{` I create a deploy token named `}<inlineCode parentName="p">{`gitlab-deploy-token`}</inlineCode>{`
(check `}<a parentName="p" {...{
        "href": "https://docs.gitlab.com/ee/user/project/deploy_tokens/index.html#gitlab-deploy-token"
      }}>{`documentation`}</a>{`
to know why this name) and grant read_registry permission only:`}</p>
    <img alt="gitlab deploy token form" caption="Adding a deploy token to GitLab" frame="box" src="gitlab-deploy-token.webp" width={80} />
    <p>{`Once created, the token is displayed in the active deploy token list:`}</p>
    <img alt="gitlab deploy token list" caption="Active deploy token list" frame="box" src="gitlab-deploy-token-list.webp" width={80} />
    <p>{`This token will populate the value of `}<inlineCode parentName="p">{`CI_DEPLOY_USER`}</inlineCode>{` and `}<inlineCode parentName="p">{`CI_DEPLOY_PASSWORD`}</inlineCode>{`
variables in pipelines, which are required to log in to the GitLab image registry
and are passed to Kubernetes to allow images to be pulled from the cluster at
the `}<a parentName="p" {...{
        "href": "#3-2"
      }}><em parentName="a">{`deploy`}</em></a>{` stage.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@3"
      }}>{`3. Add pipeline configuration file and stage declaration`}</a></h2>
    <p><em parentName="p">{`GitLab is set up, now I can create the pipeline configuration and use variables
I've added in the two previous sections`}</em></p>
    <p>{`The pipeline I'm creating consists of four stages:`}</p>
    <ol>
      <li parentName="ol">{`Package the application in Docker image.`}</li>
      <li parentName="ol">{`Deploy this image to a Kubernetes cluster and generate a unique URL to access
this application instance, such as `}<a parentName="li" {...{
          "href": "https://7c77eb36.nodejs.k0s.gaudi.sh"
        }}>{`https://7c77eb36.nodejs.k0s.gaudi.sh`}</a></li>
      <li parentName="ol">{`Promote the instance to production by pointing a non-prefixed URL to it, such
as `}<a parentName="li" {...{
          "href": "https://nodejs.k0s.gaudi.sh"
        }}>{`https://nodejs.k0s.gaudi.sh`}</a></li>
      <li parentName="ol">{`Remove the application instance.`}</li>
    </ol>
    <p>{`This is what it looks like in GitLab:`}</p>
    <img alt="gitlab pipeline interface" caption="A Vercel-like pipeline done with GitLab" frame="box" src="gitlab-k0s-pipeline.webp" width={80} />
    <p>{`In the root folder of each repository, I create a `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/.gitlab-ci.yml"
      }}><inlineCode parentName="a">{`.gitlab-ci.yml`}</inlineCode></a>{`
file that contains the configuration of the GitLab pipeline.`}</p>
    <p>{`I add the service to use the `}<a parentName="p" {...{
        "href": "https://docs.gitlab.com/charts/charts/gitlab/gitlab-runner/#using-docker-in-docker"
      }}>{`Docker-in-docker`}</a>{`
strategy in pipeline jobs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# the base image that runs the pipeline
image: docker:19.03.13

# docker service to run docker-in-docker
services:
  - docker:19.03.13-dind
`}</code></pre>
    <p>{`Then the stages that will run sequentially:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`stages:
  - package
  - deploy
  - promote
  - delete
`}</code></pre>
    <p>{`I've highlighted the different `}<span style={{
        color: '#1a1a1a',
        backgroundColor: '#e5e317',
        padding: '.25rem',
        fontWeight: 'bold'
      }}>stages</span>{' '}
and <span style={{
        color: '#1a1a1a',
        backgroundColor: '#49f667',
        padding: '.25rem',
        fontWeight: 'bold'
      }}>{`jobs`}</span>{` in
the screenshot below to illustrate how each stage will contain a unique job and
will run one after another, the `}<em parentName="p">{`delete`}</em>{` stage is not visible here:`}</p>
    <img alt="gitlab pipeline interface" caption="A pipeline is made of stages and jobs" frame="box" src="gitlab-k0s-pipeline-highlighted.webp" width={80} />
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@4"
      }}>{`4. The package stage configuration`}</a></h2>
    <p><em parentName="p">{`Before deploying to the cluster, I have to package the application in a Docker
image and push it to the Container Registry.`}</em></p>
    <p>{`Since each step has only one job, I use the same stage name and job name.
The excerpt below shows the configuration of the `}<em parentName="p">{`package`}</em>{` job:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`package: # job name
  stage: package # attach this "package" job to the "package" stage
  when: manual # remove this to deploy automatically
  image: docker:19.03.13-dind # use docker-in-docker
  script: |

    # login to the image registry
    echo \${CI_REGISTRY_PASSWORD} \\
      | docker login -u \${CI_REGISTRY_USER} \${CI_REGISTRY} --password-stdin

    # build docker image
    docker build -t \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA} \\
      --build-arg COMMIT_SHORT_HASH=\${CI_COMMIT_SHORT_SHA} .

    # push docker image
    docker push \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA}
`}</code></pre>
    <p>{`I'm not using the `}<inlineCode parentName="p">{`CI_DEPLOY_USER`}</inlineCode>{` and `}<inlineCode parentName="p">{`CI_DEPLOY_PASSWORD`}</inlineCode>{` values I created in
the `}<a parentName="p" {...{
        "href": "#2"
      }}>{`previous section`}</a>{`. Instead, I use `}<inlineCode parentName="p">{`CI_REGISTRY_USER`}</inlineCode>{` and the `}<inlineCode parentName="p">{`CI_REGISTRY_PASSWORD`}</inlineCode>{`
short living`}{` `}{`value that is allowed to write to the registry:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The password to push containers to the project's GitLab Container Registry.
Only available if the Container Registry is enabled for the project.
This password value is the same as the `}<inlineCode parentName="p">{`CI_JOB_TOKEN`}</inlineCode>{` and is valid only as long
as the job is running. Use the `}<inlineCode parentName="p">{`CI_DEPLOY_PASSWORD`}</inlineCode>{` for long-lived access to
the registry. `}<cite><a href="https://docs.gitlab.com/ee/ci/variables/predefined_variables.html#predefined-variables-reference" target="_blank">{`GitLab documentation`}</a></cite></p>
    </blockquote>
    <p>{`I use the git commit short hash to tag the built image with `}<inlineCode parentName="p">{`CI_COMMIT_SHORT_SHA`}</inlineCode>{`.`}</p>
    <p>{`I also pass this value to the Docker build command as an argument to store it
in the Docker image and return it with the `}<inlineCode parentName="p">{`/version`}</inlineCode>{` application endpoint.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@5"
      }}>{`5. The deploy stage configuration`}</a></h2>
    <p><em parentName="p">{`The Docker image has been pushed, now I can trigger a Kubernetes deployment using
`}<inlineCode parentName="em">{`kubectl`}</inlineCode>{` and manifest files stored in the repository.`}</em></p>
    <p>{`This job starts automatically when the `}<em parentName="p">{`package`}</em>{` stage completes. It doesn't
require Docker-in-docker. Instead, I use an image provided by Bitnami that
contains `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`deploy:
  stage: deploy
  needs: [package] # needs "package" stage completion before running
  image:
    name: bitnami/kubectl:latest
    entrypoint: ['']
  script: |

    # declare Kubernetes namespace to use
    export KUBE_NAMESPACE=\${CI_PROJECT_NAME}-\${CI_COMMIT_SHORT_SHA}

    # declare the public URL of the deployment
    export KUBE_INGRESS_HOST=\${CI_COMMIT_SHORT_SHA}.\${CI_PROJECT_NAME}.k0s.gaudi.sh

    # add KUBE_URL, KUBE_TOKEN and KUBE_NAMESPACE to kubectl
    ./sh/configure-kubectl.sh

    # inject values in manifests and deploy
    ./sh/deploy.sh
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`KUBE_NAMESPACE`}</inlineCode>{` refers to the Kubernetes namespace where I want to place
any resource created by `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{` during the job execution. This way I can group
components together and makes removing them easier at the `}<a parentName="p" {...{
        "href": "#7"
      }}><em parentName="a">{`delete`}</em></a>{` stage.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`KUBE_INGRESS_HOST`}</inlineCode>{` variable refers to the public URL I want to use to route
network traffic to the application instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ curl https://7c77eb36.nodejs.k0s.gaudi.sh
vigorous_cray
`}</code></pre>
    <p>{`Once variables are declared, the first thing I want to do is to configure `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{`
with the `}<inlineCode parentName="p">{`KUBE_URL`}</inlineCode>{` and `}<inlineCode parentName="p">{`KUBE_TOKEN`}</inlineCode>{` values I've added in `}<a parentName="p" {...{
        "href": "#1"
      }}>{`section 1`}</a>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "@5-1"
      }}>{`The `}<inlineCode parentName="a">{`configure-kubectl.sh`}</inlineCode>{` script`}</a></h3>
    <p>{`The script runs inside the
`}<inlineCode parentName="p">{`bitnami/kubectl`}</inlineCode>{` image and calls the `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/sh/configure-kubectl.sh"
      }}>{`configure-kubectl.sh`}</a>{`
script first`}</p>
    <p>{`All actions performed with `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{` will be done under this namespace:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`#!/bin/sh
kubectl config set-cluster k0s --server=\${KUBE_URL} --insecure-skip-tls-verify=true
kubectl config set-credentials gitlab --token=\${KUBE_TOKEN}
kubectl config set-context ci --cluster=k0s
kubectl config set-context ci --user=gitlab
kubectl config set-context ci --namespace=\${KUBE_NAMESPACE}
kubectl config use-context ci
`}</code></pre>
    <h3><a parentName="h3" {...{
        "href": "@5-2"
      }}>{`The `}<inlineCode parentName="a">{`deploy.sh`}</inlineCode>{` script`}</a></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/sh/deploy.sh"
      }}><inlineCode parentName="a">{`deploy.sh`}</inlineCode></a>{`
file contains 3 subtasks:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`It calls `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{` to store the `}<a parentName="p" {...{
            "href": "#2"
          }}><em parentName="a">{`deploy token`}</em></a>{` in a Kubernetes `}<a parentName="p" {...{
            "href": "https://kubernetes.io/docs/concepts/configuration/secret/"
          }}><em parentName="a">{`Secret`}</em></a>{`
to allow Docker images to be pulled from the GitLab `}<em parentName="p">{`Container Registry`}</em>{` by the
Kubernetes `}<a parentName="p" {...{
            "href": "https://kubernetes.io/docs/concepts/workloads/controllers/deployment/"
          }}>{`Deployment`}</a>{`
later on.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`It replaces strings such as `}<inlineCode parentName="p">{`__CI_REGISTRY_IMAGE__`}</inlineCode>{` with the actual value of the
`}<inlineCode parentName="p">{`CI_REGISTRY_IMAGE`}</inlineCode>{` variable in yaml manifest files. I use this solution
to make up for the lack of a template engine in this setup.`}</p>
      </li>
    </ol>
    <p>{`I do a simple find and replace, using sed and "|" instead of "/" because `}<inlineCode parentName="p">{`CI_REGISTRY_IMAGE`}</inlineCode>{`
contains "/" characters.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`It calls `}<inlineCode parentName="li">{`kubectl`}</inlineCode>{` to deploy the app to the cluster.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`...
# 1. add credentials to pull image from gitlab
kubectl create secret docker-registry gitlab-registry \\
    --docker-server="\${CI_REGISTRY}" \\
    --docker-username="\${CI_DEPLOY_USER}" \\
    --docker-password="\${CI_DEPLOY_PASSWORD}" \\
    --docker-email="\${GITLAB_USER_EMAIL}" \\
    -o yaml --dry-run=client | kubectl apply -f -

# 2. replace __tokens__ in yaml files with real values
find manifests -type f -exec \\
  sed -i -e 's|__CI_REGISTRY_IMAGE__|'\${CI_REGISTRY_IMAGE}'|g' {} \\;
find manifests -type f -exec \\
  sed -i -e 's|__CI_COMMIT_SHORT_SHA__|'\${CI_COMMIT_SHORT_SHA}'|g' {} \\;
...

# 3. deploy app to cluster
kubectl apply -f manifests/
...
`}</code></pre>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@6"
      }}>{`6. The promote stage`}</a></h2>
    <p><em parentName="p">{`Finally, given an existing deployment such as `}<a parentName="em" {...{
          "href": "https://7c77eb36.nodejs.k0s.gaudi.sh"
        }}>{`7c77eb36.nodejs.k0s.gaudi.sh`}</a>{`,
I want to point the production domain `}<a parentName="em" {...{
          "href": "https://nodejs.k0s.gaudi.sh"
        }}>{`nodejs.k0s.gaudi.sh`}</a>{`
to the same deployment.`}</em></p>
    <p>{`This stage creates a new Kubernetes `}<a parentName="p" {...{
        "href": "https://kubernetes.io/docs/concepts/services-networking/ingress/"
      }}>{`Ingress`}</a>{`
to route the production domain to the same container I've deployed in the
previous section:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`promote to prod:
  when: manual
  needs: [deploy]
  image:
    name: bitnami/kubectl:latest
    entrypoint: ['']
  stage: promote
  script: |
    export KUBE_NAMESPACE=\${CI_PROJECT_NAME}-\${CI_COMMIT_SHORT_SHA}
    export KUBE_INGRESS_HOST=\${CI_PROJECT_NAME}.k0s.gaudi.sh
    ./sh/configure-kubectl.sh
    ./sh/promote.sh
`}</code></pre>
    <p>{`Like in the previous `}<a parentName="p" {...{
        "href": "#5-1"
      }}>{`deploy job`}</a>{`, `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{` is configured with the `}<inlineCode parentName="p">{`configure-kubectl.sh`}</inlineCode>{` script.`}</p>
    <p>{`Then, the `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/sh/promote.sh"
      }}>{`promote.sh`}</a>{` is executed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`...
# delete existing ingress
kubectl delete ingress \\
  --all-namespaces \\
  --field-selector metadata.name=api-prod \\
  -l app=\${CI_PROJECT_NAME},tier=backend

# add new ingress
cat <<EOF | kubectl apply -f -
apiVersion: networking.k8s.io/v1
kind: Ingress
metadata:
  name: api-prod
  labels:
    app: \${CI_PROJECT_NAME}
    tier: backend
...
  rules:
    - host: \${KUBE_INGRESS_HOST}
...
`}</code></pre>
    <p>{`Now both URLs return the same version value since they point to the same
deployment:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ curl https://7c77eb36.nodejs.k0s.gaudi.sh/version
7c77eb36
$ curl https://nodejs.k0s.gaudi.sh/version
7c77eb36
`}</code></pre>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@7"
      }}>{`7. The delete stage`}</a></h2>
    <p><em parentName="p">{`To clean up deployments, I'm adding a fourth stage to delete Kubernetes resources.
Docker images are not removed from the Container Registry, though.`}</em></p>
    <p>{`To remove all resources attached to the deployment, I simply delete the Kubernetes
namespace:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`delete env:
  when: manual
  needs: [deploy]
  image:
    name: bitnami/kubectl:latest
    entrypoint: ['']
  stage: delete
  script: |
    export KUBE_NAMESPACE=\${CI_PROJECT_NAME}-\${CI_COMMIT_SHORT_SHA}
    ./sh/configure-kubectl.sh
    kubectl delete namespace $KUBE_NAMESPACE
`}</code></pre>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@8"
      }}>{`Next step`}</a></h2>
    <p>{`All stages of the pipeline are set up, now I must add two things to make it work:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` to build the Docker image in the `}<a parentName="p" {...{
            "href": "#4"
          }}><em parentName="a">{`package`}</em></a>{` stage.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Kubernetes manifests I'm applying in the `}<inlineCode parentName="p">{`deploy.sh`}</inlineCode>{` with the `}<inlineCode parentName="p">{`kubectl apply -f manifests/`}</inlineCode>{`
command in the `}<a parentName="p" {...{
            "href": "#5"
          }}><em parentName="a">{`deploy`}</em></a>{` stage.`}</p>
      </li>
    </ul>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part3"
        }}>{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part III: Applications and the Dockerfile`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      